@media only screen and (min-width : 1280px){

.nav{
position: relative;
}

.firstDiv {
    /* background-color: rgb(14, 55, 57); */
    background-color: #041e42;
    height: 11vh;
    width: 100vw;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 3rem;
}


.logo {
    height: 110px;
    object-fit: contain;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 2rem;
    cursor: pointer;
}

.FirstDiv2ndBloc {
    display: none;
}


.secondDiv {
    /* background-color: rgb(18, 65, 66); */
    background-color: #00457c;
    height: 8vh;
    width: 100vw;
    position: relative;
}

.span {
    font-family: Open Sans;
}

.item1 {
    font-family: Open Sans;
    font-size: 14px;
    /* line-height: 36px; */
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #fff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 120px;
    display: flex;
    gap: 10px;
}
.item1:hover {
    cursor: pointer;
    color: #e5936b;
}

.item2 {
    font-family: Open Sans;
    font-size: 14px;
    /* line-height: 36px; */
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #fff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 260px;
    display: flex;
    gap: 10px;
}

.item2:hover {
    cursor: pointer;
    color: #e5936b;
}

.item3 {
    font-family: Open Sans;
    font-size: 14px;
    /* line-height: 36px; */
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #fff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 490px;
    display: flex;
    gap: 10px;
}

.item3:hover {
    cursor: pointer;
    color: #e5936b;
}

.nav_button {
    width: 12vw;
    font-family: Open Sans;
    font-size: 13px;
    cursor: pointer;
    color: #fff;
    outline:none;
    border: none;
    font-weight: 400;
    letter-spacing: 2px;
    /* border-radius: 0.2vw; */
    background-color: #041e42;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px; 
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 10px;
    padding-bottom: 10px;
}

.nav_button:hover{
    transition: all 0.2s;
    background-color: #ffffff;
    color: rgb(0,0,0);
}

.item4 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 260px;
}


.modal {
    border-radius: 0.375rem/* 6px */;
    top: 0px;
    right: 0px;
    position: fixed;
    display: block;
    z-index: 9999;
    height: 25%;
    width: 25%;
    background-color: #6e6b6b;
    animation: slideInLeft 0.5s ease; /* Animation de défilement vers la gauche */
}


.modal > button > svg:hover {
    stroke: #fff;
}}







@media only screen and (min-width : 1024px) and (max-width : 1280px){
    .firstDiv {
        /* background-color: rgb(14, 55, 57); */
        background-color: #041e42;
        height: 11vh;
        width: 100vw;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 3rem;
    }
    
    
    .logo {
        height: 110px;
        object-fit: contain;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin-left: 2rem;
        cursor: pointer;
    }

    .FirstDiv2ndBloc {
        display: none;
    }
    
    .secondDiv {
        /* background-color: rgb(18, 65, 66); */
        background-color: #00457c;
        height: 8vh;
        width: 100vw;
        position: relative;
    }
  
    .span {
        font-family: Open Sans;
    }
    
    .item1 {
        font-family: Open Sans;
        font-size: 14px;
        /* line-height: 36px; */
        font-weight: 400;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: #fff;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 120px;
        display: flex;
        gap: 10px;
    }
    .item1:hover {
        cursor: pointer;
        color: #e5936b;
    }
    
    .item2 {
        font-family: Open Sans;
        font-size: 14px;
        /* line-height: 36px; */
        font-weight: 400;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: #fff;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 260px;
        display: flex;
        gap: 10px;
    }
    
    .item2:hover {
        cursor: pointer;
        color: #e5936b;
    }
    
    .item3 {
        font-family: Open Sans;
        font-size: 14px;
        /* line-height: 36px; */
        font-weight: 400;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: #fff;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 490px;
        display: flex;
        gap: 10px;
    }
    
    .item3:hover {
        cursor: pointer;
        color: #e5936b;
    }
    
    .nav_button {
        width: 12vw;
        font-family: Open Sans;
        font-size: 13px;
        cursor: pointer;
        color: #fff;
        outline:none;
        border: none;
        font-weight: 400;
        letter-spacing: 2px;
        /* border-radius: 0.2vw; */
        background-color: #041e42;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        gap: 10px; 
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    
    .nav_button:hover{
        transition: all 0.2s;
        background-color: #ffffff;
        color: rgb(0,0,0);
    }
    
    .item4 {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 260px;
    }
    
    
    .modal {
        border-radius: 0.375rem/* 6px */;
        top: 0px;
        right: 0px;
        position: fixed;
        display: block;
        z-index: 9999;
        height: 25%;
        width: 25%;
        background-color: #6e6b6b;
        animation: slideInLeft 0.5s ease; /* Animation de défilement vers la gauche */
    }
    
    
    .modal > button > svg:hover {
        stroke: #fff;
    }}
    

    




    @media only screen and (min-width : 768px) and (max-width : 1024px){
        .firstDiv {
            /* background-color: rgb(14, 55, 57); */
            background-color: #041e42;
            height: 11vh;
            width: 100vw;
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        
        
        .logo {
            height: 110px;
            object-fit: contain;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            margin-left: 2rem;
            cursor: pointer;
        }
        
        .FirstDiv2ndBloc {
            /* display: block; */
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            margin-right: 20px;
        }
        
        
        .secondDiv {
            display: none;
        }

        
        .modalSmallScreen {
            background-color: #041e42; 
            position: absolute;
            display: block;
            top: 11vh;
            left: 0;
            width: 100vw;
            height: 50vh;
            z-index: 9990;
            border-radius: 0 0 0.375rem 0.375rem/* 6px */;
            animation: slideInBottom 0.7s ease; /* Animation de défilement vers le bas */
            border-width: 0.2px;
            border-color: #e8d4a2;
        }
 
        
        .item2 {
            font-family: Open Sans;
            font-size: 12px;
            /* line-height: 36px; */
            font-weight: 400;
            letter-spacing: 2px;
            text-transform: uppercase;
            color: #fff;
            position: absolute;
            left: 20px;
            width: 90vw;
            margin-top: 40px;
            padding-bottom: 20px;
            border-bottom: 1px solid #e7e7e7;
            display: flex;
            gap: 10px;
        }
        
        .item2:hover {
            cursor: pointer;
            color: #e5936b;
        }
        
        .item3 {
            font-family: Open Sans;
            font-size: 12px;
            /* line-height: 36px; */
            font-weight: 400;
            letter-spacing: 2px;
            text-transform: uppercase;
            color: #fff;
            position: absolute;
            left: 20px;
            margin-top: 110px;
            width: 90vw;
            padding-bottom: 20px;
            border-bottom: 1px solid #e7e7e7;
            display: flex;
            gap: 10px;
        }
        
        .item3:hover {
            cursor: pointer;
            color: #e5936b;
        }
        

        
        .item4 {
            position: absolute;
            left: 20px;
            margin-top: 180px;
            width: 90vw;
            padding-bottom: 20px;
            border-bottom: 1px solid #e7e7e7;
        }
        
        
        .modal {
            border-radius: 0.375rem/* 6px */;
            top: 0px;
            right: 0px;
            position: fixed;
            display: block;
            z-index: 9999;
            height: 25%;
            width: 25%;
            background-color: #6e6b6b;
            animation: slideInLeft 0.5s ease; /* Animation de défilement vers la gauche */
        }
        
        
        .modal > button > svg:hover {
            stroke: #fff;
        }
    }






    
    @media only screen and (min-width : 320px) and (max-width : 768px){
        .firstDiv {
            /* background-color: rgb(14, 55, 57); */
            background-color: #041e42;
            height: 11vh;
            width: 100vw;
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        
        
        .logo {
            height: 90px;
            object-fit: contain;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            margin-left: 5vw;
            cursor: pointer;
        }
        
        .FirstDiv2ndBloc {
            /* display: block; */
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            margin-right: 20px;
        }
        
        
        .secondDiv {
            display: none;
        }

        
        .modalSmallScreen {
            background-color: #041e42; 
            position: absolute;
            display: block;
            top: 11vh;
            left: 0;
            width: 100vw;
            height: 50vh;
            z-index: 9990;
            border-radius: 0 0 0.375rem 0.375rem/* 6px */;
            animation: slideInBottom 0.7s ease; /* Animation de défilement vers le bas */
            border-width: 0.2px;
            border-color: #e8d4a2;
        }
 
        
        .item2 {
            font-family: Open Sans;
            font-size: 12px;
            /* line-height: 36px; */
            font-weight: 400;
            letter-spacing: 2px;
            text-transform: uppercase;
            color: #fff;
            position: absolute;
            left: 20px;
            width: 90vw;
            margin-top: 40px;
            padding-bottom: 20px;
            border-bottom: 1px solid #e7e7e7;
            display: flex;
            gap: 10px;
        }
        
        .item2:hover {
            cursor: pointer;
            color: #e5936b;
        }
        
        .item3 {
            font-family: Open Sans;
            font-size: 12px;
            /* line-height: 36px; */
            font-weight: 400;
            letter-spacing: 2px;
            text-transform: uppercase;
            color: #fff;
            position: absolute;
            left: 20px;
            margin-top: 110px;
            width: 90vw;
            padding-bottom: 20px;
            border-bottom: 1px solid #e7e7e7;
            display: flex;
            gap: 10px;
        }
        
        .item3:hover {
            cursor: pointer;
            color: #e5936b;
        }
        

        
        .item4 {
            position: absolute;
            left: 20px;
            margin-top: 180px;
            width: 90vw;
            padding-bottom: 20px;
            border-bottom: 1px solid #e7e7e7;
        }
        
        
        .modal {
            border-radius: 0.375rem/* 6px */;
            top: 0px;
            right: 0px;
            position: fixed;
            display: block;
            z-index: 9999;
            height: 25%;
            width: 25%;
            background-color: #6e6b6b;
            animation: slideInLeft 0.5s ease; /* Animation de défilement vers la gauche */
        }
        
        
        .modal > button > svg:hover {
            stroke: #fff;
        }
    }

        
        @keyframes slideInBottom {
          from {
            transform: translateY(-100%);
          }
          to {
            transform: translateY(0);
          }
        }

        @keyframes slideInLeft {
            from {
              transform: translateX(100%);
            }
            to {
              transform: translateX(0);
            }
          }
        