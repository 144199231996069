/* Remove default eye icon for password input in Internet Explorer */
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
    display: none;
}

.MailVerifSentence {
    font-family: 'Arial', sans-serif;
    font-size: 16px;
    color: #333; /* Couleur de texte sombre */
    line-height: 1.5; /* Espacement entre les lignes */
    letter-spacing: 1px;
}

.MailVerifSentence:hover {
    color: #5aa6dc;
}