.input {
    @apply w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#00457c] focus:shadow-md
}

.input_A {
    @apply w-full rounded-md border border-[#e0e0e0] bg-white py-1 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#00457c] focus:shadow-md
}

.react-autosuggest__suggestions-container {
    @apply mt-1 max-h-48 overflow-y-auto cursor-pointer rounded-md border border-[#e0e0e0] bg-white
}
