@media only screen and (min-width : 1280px) {
.banner {
    position: relative;
    object-fit: contain;
    height: 81vh;
    top: 0px;
}

/* .banner--fadeBottom {
    height: 7rem;
    background-image: linear-gradient(
        180deg,
        transparent,
        rgba(37, 37, 37, 0.61),
        #111
    );
} */

.banner_contents {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

 .banner_title {
    max-width: 700px;
    font-family: Open Sans;
    font-size: 45px;
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #fff;
    text-align: center;
}

/* .banner_text {
    color: #ffffff;
    font-weight: 500;
    max-width: 550px;
    white-space: pre-wrap;
    line-height: 2;
}  */ 

.banner_button {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-family: Open Sans;
    cursor: pointer;
    color: #fff;
    outline:none;
    border: none;
    font-weight: 400;
    letter-spacing: 2px;
    /* border-radius: 0.2vw; */
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 13px;
    padding-bottom: 13px;
    background-color: #00457c;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    gap: 10px;
    
}

.banner_button:hover{
    transition: all 0.2s;
    background-color: #ffffff;
    color: rgb(0,0,0);
}
}

@media only screen and (min-width : 1024px) and (max-width : 1280px){

    .banner {
        position: relative;
        object-fit: contain;
        height: 81vh;
        top: 0px;
    }
    
    /* .banner--fadeBottom {
        height: 7rem;
        background-image: linear-gradient(
            180deg,
            transparent,
            rgba(37, 37, 37, 0.61),
            #111
        );
    } */
    
    .banner_contents {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    
     .banner_title {
        max-width: 700px;
        font-family: Open Sans;
        font-size: 45px;
        font-weight: 400;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: #fff;
        text-align: center;
    }
    
    /* .banner_text {
        color: #ffffff;
        font-weight: 500;
        max-width: 550px;
        white-space: pre-wrap;
        line-height: 2;
    }  */ 
    
    .banner_button {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        font-family: Open Sans;
        cursor: pointer;
        color: #fff;
        outline:none;
        border: none;
        font-weight: 400;
        letter-spacing: 2px;
        /* border-radius: 0.2vw; */
        padding-left: 3rem;
        padding-right: 3rem;
        padding-top: 13px;
        padding-bottom: 13px;
        background-color: #00457c;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        gap: 10px;
        
    }
    
    .banner_button:hover{
        transition: all 0.2s;
        background-color: #ffffff;
        color: rgb(0,0,0);
    }
}



@media only screen and (min-width : 768px) and (max-width : 1024px){

    .banner {
        position: relative;
        object-fit: contain;
        height: 89vh;
        top: 0px;
    }
    
    /* .banner--fadeBottom {
        height: 7rem;
        background-image: linear-gradient(
            180deg,
            transparent,
            rgba(37, 37, 37, 0.61),
            #111
        );
    } */
    
    .banner_contents {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    
     .banner_title {
        max-width: 700px;
        font-family: Open Sans;
        font-size: 45px;
        font-weight: 400;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: #fff;
        text-align: center;
    }
    
    /* .banner_text {
        color: #ffffff;
        font-weight: 500;
        max-width: 550px;
        white-space: pre-wrap;
        line-height: 2;
    }  */ 
    
    .banner_button {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        font-family: Open Sans;
        cursor: pointer;
        color: #fff;
        outline:none;
        border: none;
        font-weight: 400;
        letter-spacing: 2px;
        /* border-radius: 0.2vw; */
        padding-left: 3rem;
        padding-right: 3rem;
        padding-top: 13px;
        padding-bottom: 13px;
        background-color: #00457c;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        gap: 10px;
        
    }
    
    .banner_button:hover{
        transition: all 0.2s;
        background-color: #ffffff;
        color: rgb(0,0,0);
    }
}









@media only screen and (min-width : 320px) and (max-width : 768px){

    .banner {
        position: relative;
        object-fit: contain;
        height: 89vh;
        top: 0px;
    }
    
    /* .banner--fadeBottom {
        height: 7rem;
        background-image: linear-gradient(
            180deg,
            transparent,
            rgba(37, 37, 37, 0.61),
            #111
        );
    } */
    
    .banner_contents {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    
     .banner_title {
        max-width: 700px;
        font-family: Open Sans;
        font-size: 45px;
        font-weight: 400;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: #fff;
        text-align: center;
    }
    
    /* .banner_text {
        color: #ffffff;
        font-weight: 500;
        max-width: 550px;
        white-space: pre-wrap;
        line-height: 2;
    }  */ 
    
    .banner_button {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        font-family: Open Sans;
        cursor: pointer;
        color: #fff;
        outline:none;
        border: none;
        font-weight: 400;
        letter-spacing: 2px;
        /* border-radius: 0.2vw; */
        padding-left: 3rem;
        padding-right: 3rem;
        padding-top: 13px;
        padding-bottom: 13px;
        background-color: #00457c;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        gap: 10px;
        
    }
    
    .banner_button:hover{
        transition: all 0.2s;
        background-color: #ffffff;
        color: rgb(0,0,0);
    }
}