.auth_button {
    width: 12vw;
    font-family: Open Sans;
    font-size: 13px;
    cursor: pointer;
    color: #fff;
    outline:none;
    border: none;
    font-weight: 400;
    letter-spacing: 2px;
    /* border-radius: 0.2vw; */
    background-color: #041e42;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 3rem;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    /* gap: 10px;  */
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 15px;
    padding-bottom: 13px;
}

.auth_button:hover{
    transition: all 0.2s;
    background-color: #ffffff;
    color: rgb(0,0,0);
}